
<template>
  <div>
    <b-card>
      <validation-observer ref="playerForm">
        <b-toast
          :visible="isFailed"
          title="Warning"
          variant="warning"
        >
          {{ $t('messages.invalid_form') }}
        </b-toast>

        <form @submit.prevent="onSubmit">
          <validation-provider
            v-slot="{ errors }"
            name="username"
            rules="min:10|regex:^([0-9]+)$"
          >
            <b-form-group
              label="ยูสเซอร์เนม (เบอร์)"
              label-for="username"
              label-cols-sm="12"
              label-cols-lg="3"
            >
              <b-form-input
                id="username"
                v-model="playerData.username"
                v-mask="'##########'"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                aria-describedby="input-live-feedback"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :name="$t('user.password')"
            rules="required"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('user.password')} *`"
              label-for="agent_password"
            >
              <b-input-group>
                <b-form-input
                  id="agent_password"
                  v-model="playerData.password"
                  :type="isShowPassword?'text':'password'"
                  :state="errors[0] ? false : null"
                  autocomplete="off"
                />
                <template #append>
                  <b-button @click="isShowPassword = !isShowPassword">
                    <feather-icon
                      :icon="isShowPassword?'EyeIcon':'EyeOffIcon'"
                    />
                  </b-button>
                </template>
              </b-input-group>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            label="ธนาคาร"
            label-for="bank-code"
          >
            <b-form-select
              v-model="playerData.bankCode"
              :options="bankList"
            >
              <template #first>
                <b-form-select-option
                  value=""
                  disabled
                >
                  {{ `-- ${$t('fields.click_select')} --` }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            label="เลขบัญชี"
            label-for="bank-account-number"
          >
            <b-form-input
              id="bank-account-number"
              v-model="playerData.bankAccountNumber"
            />
          </b-form-group>

          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            label="ชื่อบัญชี"
            label-for="bank-account-name"
          >
            <b-form-input
              id="bank-account-name"
              v-model="playerData.bankAccountName"
            />
          </b-form-group>

          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            label="ไลน์"
            label-for="line-id"
          >
            <b-form-input
              id="line-id"
              v-model="playerData.lineId"
            />
          </b-form-group>

          <div class="text-right">
            <b-button
              :to="{name: 'VIEW_PLAYERS'}"
              class="mr-2"
              variant="light"
            >
              {{ $t('buttons.back') }}
            </b-button>
            <b-overlay
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="isCreatingPlayer"
              >
                {{ $t('buttons.save') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { required } from '@validations'
import { mapActions, mapState } from 'vuex'
import { banks } from '@/libs/constants.js';

export default {
  page: {
    title: 'New Player',
  },
  data() {
    return {
      required,
      isFailed: false,

      bankList: banks.map((bank) => {
        return {
          value: bank.bank_code,
          text: bank.bank_name_th
        }
      }),
      isShowPassword: false,
      playerData: {
        username: '',
        password: '',
        bankAccountNumber: '',
        bankAccountName: '',
        bankCode: null,
        lineId: '',
        registerFrom: 'other'
      },
    }
  },
  computed: {
    ...mapState({
      isCreatingPlayer: (state) =>
          state.player.isCreatingPlayer,
    }),
  },
  methods: {
    ...mapActions(['createPlayer']),
    onSubmit() {
      this.$refs.playerForm.validate().then( async (success) => {
        if (success) {
          this.isFailed = false
          this.createPlayer(this.playerData)
        } else {
          this.isFailed = true

        }
      })
    },
  },
}
</script>
